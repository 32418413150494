import Divider from 'shared/divider';
import emailjs from '@emailjs/browser';
import { useFormik } from 'formik';
import { motion } from 'framer-motion';
import * as Yup from 'yup';
import Input from 'shared/formInput';
import { classNames } from 'shared';

interface Props {}

const ContactUs = (props: Props) => {
	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			message: '',
			number: '',
			field: ''
		},
		validationSchema: Yup.object().shape({
			firstName: Yup.string().required('Please Insert your First Name!'),
			lastName: Yup.string().required('Please Insert your Last Name!'),
			email: Yup.string().email().required('Email is required for contact you.!'),
			message: Yup.string()
				.min(10, 'Too Short Message!')
				.max(500, 'Too Long Message!')
				.required('Please write what you want to tell us about'),
			number: Yup.string().required('Please Insert Your Number.!'),
			field: Yup.string().required('Please Choose Which Field you are interested in .!')
		}),
		onSubmit: (values) => {
			emailjs.send('service_3su484r', 'template_rul34x5', values, 'PSautWbQLOV4aRe_o').then(
				(result) => {
					formik.resetForm();
				},
				(error) => {
					console.log(error.text);
				}
			);
		}
	});
	const notificationMethods = [
		{ id: 'graphic', title: 'Graphic Design' },
		{ id: 'ui-ux', title: 'UI / UX Design' },
		{ id: 'mobile', title: 'Mobile Development' },
		{ id: 'web', title: 'Web Development' },
		{ id: 'other', title: 'Other' }
	];
	return (
		<div className='w-full h-full px-8 md:px-16  contact-us-bg-arrows'>
			<div className='w-full flex flex-col items-start justify-start gap-3 py-10'>
				<span className='text-[#FFFFFFCC] font-light text-[12px]'>Contact Us</span>
				<h1 className='font-semibold text-3xl sm:text-4xl text-white'>What Services do you need ?!</h1>
				<Divider />
				<p className='text-[#FFFFFFB2] w-full h-auto font-poppins text-sm text-left mt-2 md:w-90 text-wrap max-w-lg leading-normal'>
					Fill up the form and our Team will get back to you within 24 hours.
				</p>
			</div>
			<div>
				<fieldset className='mt-4'>
					<div className='space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10'>
						{notificationMethods.map((notificationMethod) => (
							<div key={notificationMethod.id} className='flex items-center'>
								<input
									id={notificationMethod.id}
									name='notification-method'
									type='radio'
									onChange={() => formik.setFieldValue('field', notificationMethod.title)}
									defaultChecked={notificationMethod.id === 'email'}
									className={classNames(
										formik.errors.field
											? 'h-4 w-4 border-red-500 text-red-500 focus:ring-red-500'
											: 'h-4 w-4 border-white text-[#377DFF] focus:ring-[#377DFF]'
									)}
								/>
								<label
									htmlFor={notificationMethod.id}
									onClick={() => formik.setFieldValue('field', notificationMethod.title)}
									className={classNames(
										formik.errors.field
											? 'ml-3 block text-sm font-medium text-red-500 cursor-pointer'
											: 'ml-3 block text-sm font-medium text-white cursor-pointer'
									)}>
									{notificationMethod.title}
								</label>
							</div>
						))}
					</div>
					{formik.errors.field && (
						<p className='mt-2 text-sm text-red-600'>{formik.errors.field.toString()}</p>
					)}
				</fieldset>
			</div>
			<div className='py-10 px-0 md:px-10 lg:col-span-2 xl:p-12 w-full mx-auto'>
				<form
					onSubmit={formik.handleSubmit}
					className='mt-6 grid grid-cols-1 sm:gap-y-20 gap-y-10 sm:grid-cols-2 sm:gap-x-8'
					noValidate>
					<Input
						formik={formik}
						type='text'
						id='firstName'
						name='firstName'
						label='First Name'
						required={true}
						placeholder='Type Your First Name...'
					/>
					<Input
						formik={formik}
						type='text'
						id='lastName'
						name='lastName'
						label='Last Name'
						required={true}
						placeholder='Type Your Last Name...'
					/>
					<Input
						formik={formik}
						type='email'
						id='email'
						name='email'
						label='Email Address'
						required={true}
						placeholder='Type Your Email Address...'
					/>
					<Input
						formik={formik}
						type='text'
						id='phone'
						name='number'
						label='Phone Number'
						required={true}
						placeholder='Type Your Phone Number...'
					/>

					<div className='sm:col-span-2'>
						<div className='flex justify-between'>
							<label
								htmlFor='message'
								className={classNames(
									formik.errors.message && formik.touched.message
										? 'block text-sm font-medium text-red-500'
										: 'block text-sm font-medium text-white'
								)}>
								Message
							</label>
							<span id='message-max' className='text-sm text-gray-500'>
								Max. 500 characters
							</span>
						</div>
						<div className='mt-1'>
							<textarea
								id='message'
								name='message'
								value={formik.values.message}
								onChange={formik.handleChange}
								placeholder='Write your message...'
								rows={1}
								className={classNames(
									formik.errors.message && formik.touched.message
										? 'block w-full bg-transparent text-sm border-b-2 pb-3 border-red-500 pt-3 text-red-500  text-red-500'
										: 'block w-full bg-transparent text-sm border-b-2 pb-3 border-[#FFFFFF66] pt-3  text-[#FFFFFF66]'
								)}
								aria-describedby='message-max'
							/>
						</div>
						{formik.errors.message && formik.touched.message && (
							<p className='mt-2 text-sm text-red-600'>{formik.errors.message.toString()}</p>
						)}
					</div>
					<div className='relative sm:col-span-2 sm:flex sm:justify-end'>
						<div className='bottom-0 right-0 absolute -z-10 w-[200px] h-[50px] opacity-50 bg-gradient-to-l from-[#8504c4] to-[#8504c4] blur-[150px]' />
						<motion.button
							type='submit'
							whileHover={{ scale: 1.2 }}
							className='mt-2 block items-center justify-center  border border-transparent bg-[#377DFF] w-[220px] h-[50px] text-base font-medium text-white rounded-[7px]'>
							Send
						</motion.button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ContactUs;
