import AboutUs from 'components/about-us';
import ContactUs from 'components/contact-us';
import LatestProjects from 'components/latest-projects-section';
import Reviews from 'components/reviews';
import Services from 'components/Services/ServicesSection/Services';
import TeamProfiles from 'components/team-profiles-section';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import HeroSection from 'components/hero_section';
import Footer from 'components/footer';
import UPButton from 'shared/up-button';

export default function Home() {
	const router = useLocation();

	useEffect(() => {
		const target = document.getElementById(router.hash);

		target?.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
	}, [router.hash]);

	//testing animation

	const refs: { [key: string]: any } = {
		home: useRef<HTMLDivElement | null>(null),
		services: useRef<HTMLDivElement | null>(null),
		team: useRef<HTMLDivElement | null>(null),
		portfolio: useRef<HTMLDivElement | null>(null),
		reviews: useRef<HTMLDivElement | null>(null),
		contact: useRef<HTMLDivElement | null>(null),
		about: useRef<HTMLDivElement | null>(null)
	};
	// const view: { [key: string]: any } = {
	// 	home: useInView(refs.home),
	// 	services: useInView(refs.services),
	// 	portfolio: useInView(refs.portfolio),
	// 	team: useInView(refs.team),
	// 	contact: useInView(refs.contact),
	// 	about: useInView(refs.about),
	// 	reviews: useInView(refs.reviews)
	// };

	// useEffect(() => {
	// 	const target = Object.keys(view).find((item) => view[item] === true);
	// 	console.log(target);
	// }, [view.home, view.services, view.portfolio, view.team, view.contact, view.about, view.reviews]);

	return (
		<main>
			<div id='#home' ref={refs.home}>
				<HeroSection />
			</div>
			<div id='#our-services' ref={refs.services}>
				<Services />
			</div>
			<div id='#portfolio' ref={refs.portfolio}>
				<LatestProjects />
			</div>
			<div id='#our-team' ref={refs.team}>
				<TeamProfiles />
			</div>
			<div id='#contact-us' ref={refs.contact}>
				<ContactUs />
			</div>
			<div id='#about-us' ref={refs.about}>
				<AboutUs />
			</div>
			<div id='#reviews' ref={refs.reviews}>
				<Reviews />
			</div>
			<div id='#footer' ref={refs.reviews}>
				<Footer />
			</div>
			<UPButton />
		</main>
	);
}
