import ProjectCard from 'components/projects-card';
import { useState } from 'react';
import { classNames } from 'shared';
import Divider from 'shared/divider';
import projects from 'assets/projects.json';
import { motion } from 'framer-motion';
import { Tabs } from 'models/tabs.enums';
export interface Project {
	id: number;
	image: string;
	name: string;
	type: string;
	href: string;
	description: string;
}
type Props = {};
const LatestProjects = (props: Props) => {
	const [tab, setTab] = useState<number>(1);
	const tabs = [
		{ name: 'UI/UX Design', current: tab === 1 },
		{ name: 'Web Development', current: tab === 2 },
		{ name: 'Mobile Development', current: tab === 3 },
		{ name: 'Graphic Design', current: tab === 4 }
	];
	const getContent = () => {
		const content: { [key: number]: any } = {
			1: Tabs.UI_UX,
			2: Tabs.WEB,
			3: Tabs.MOBILE,
			4: Tabs.GRAPHIC
		};
		return content[tab];
	};
	return (
		<div className='bg-arrows min-h-screen relative mx-auto flex flex-col items-center justify-center gap-8 flex-shrink-0 w-full my-40'>
			<div className='top-5 absolute -z-10 w-full h-[250px] opacity-25 bg-gradient-to-l from-[#377dff] to-[#8504c4] blur-[150px]' />
			<div className='text-center text-white flex flex-col items-center justify-center gap-3'>
				<span className='text-[#FFFFFFCC] text-sm font-light'>Portfolio</span>
				<h1 className='text-3xl sm:text-4xl font-semibold'>Our Latest Projects !!</h1>
				<p className='text-[#FFFFFFB2] w-full h-auto font-poppins text-sm text-center mt-4 md:w-90 text-wrap max-w-lg leading-normal'>
					This is what we did in each field.
				</p>
				<Divider />
			</div>
			<div className='w-full max-w-4xl mx-auto'>
				<div className='sm:hidden w-10/12 mx-auto border-2 rounded-md pr-2'>
					<label htmlFor='tabs' className='sr-only'>
						Select a tab
					</label>
					<select
						id='tabs'
						name='tabs'
						className='block w-full rounded-sm px-2 py-1 bg-transparent focus:border-none focus:outline-none text-gray-300'
						defaultValue={tabs?.find((tab) => tab.current)?.name}
						onChange={(e) => setTab(tabs.findIndex((tab) => e.target.value === tab.name) + 1)}>
						{tabs.map((tab, index) => (
							<option key={tab.name} value={tab.name} className='text-gray-800'>
								{tab.name}
							</option>
						))}
					</select>
				</div>
				<div className='hidden sm:block px-5'>
					<div className='border-b border-gray-200'>
						<nav className='-mb-px flex' aria-label='Tabs'>
							{tabs.map((tab, index) => (
								<button
									key={tab.name}
									onClick={() => setTab(index + 1)}
									className={classNames(
										tab.current
											? 'border-white text-white font-semibold text-xl'
											: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 text-sm',
										'w-1/4 py-4 px-1 text-center border-b-2 font-medium whitespace-nowrap'
									)}
									aria-current={tab.current ? 'page' : undefined}>
									{tab.name}
								</button>
							))}
						</nav>
					</div>
				</div>
			</div>
			<motion.div
				initial={{ x: -200 }}
				whileInView={{ x: 0 }}
				viewport={{ once: true }}
				transition={{ duration: 1 }}
				className='w-full sm:hidden grid justify-center items-center mx-auto max-w-5xl md:grid-cols-2 grid-cols-1 lg:grid-cols-3'>
				{(projects as { [key: string]: any })[getContent()].map((item: Project, index: number) => (
					<ProjectCard item={item} key={index} type={getContent()} />
				))}
			</motion.div>
			<motion.div
				initial={{ scale: 0.5, opacity: 0 }}
				whileInView={{ scale: 1, opacity: 1 }}
				viewport={{ once: true }}
				transition={{ duration: 1 }}
				className='w-full sm:grid hidden justify-center items-center mx-auto max-w-5xl md:grid-cols-2 grid-cols-1 lg:grid-cols-3'>
				{(projects as { [key: string]: any })[getContent()].map((item: Project, index: number) => (
					<ProjectCard item={item} key={index} type={getContent()} />
				))}
			</motion.div>
		</div>
	);
};

export default LatestProjects;
