import Footer from 'components/footer';
import Navbar from 'components/navbar';
import MemberComponent from 'components/Team/Member';
import React from 'react';

type Props = {};

const Members = (props: Props) => {
	return (
		<div>
			<Navbar />
			<MemberComponent />
			<Footer />
		</div>
	);
};

export default Members;
