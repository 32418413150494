import logo from 'assets/images/logo.png';
import { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { classNames } from 'shared';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

export default function Navbar() {
	const location = useLocation();

	const [showBg, setShowBg] = useState<boolean>(false);
	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (window.scrollY > 500) {
				setShowBg(true);
			} else setShowBg(false);
		});
		return () => {
			window.removeEventListener('scroll', () => console.log('Event Listener Removed'));
		};
	}, []);
	return (
		<div
			className={classNames(
				showBg ? 'bg-[#000000e8] tablet:h-[120px]' : 'tablet:h-[150px]',
				'absolute top-0 left-[50%] z-[100000] translate-x-[-50%] w-full flex items-center justify-center transition-all duration-700'
			)}>
			<div className='max-w-7xl px-10 flex-row items-center justify-center gap-5 lg:gap-20 mx-auto tablet:flex hidden'>
				<div className='flex items-center gap-8 md:gap-12 xl:gap-20'>
					<NavLink
						to='/'
						className={classNames(
							location.hash === ''
								? 'text-white text-bold border-b-[1px]'
								: 'text-[#FFFFFF4D] hover:text-white',
							'whitespace-nowrap'
						)}>
						Home
					</NavLink>
					<NavLink
						to='/#our-services'
						className={classNames(
							location.hash === '#our-services'
								? 'text-white border-b-2'
								: 'text-[#FFFFFF4D] hover:text-white',
							'whitespace-nowrap'
						)}>
						Our Services
					</NavLink>
					<NavLink
						to='/#portfolio'
						className={classNames(
							location.hash === '#portfolio'
								? 'text-white text-bold border-b-[1px]'
								: 'text-[#FFFFFF4D] hover:text-white',
							'whitespace-nowrap'
						)}>
						Portfolio
					</NavLink>
				</div>
				<img src={logo} alt='logo' className={classNames(showBg ? 'h-[80px]' : 'h-[100px]')} />
				<div className='flex items-center gap-8 md:gap-12 xl:gap-20'>
					<NavLink
						to='/#our-team'
						className={classNames(
							location.hash === '#our-team'
								? 'text-white text-bold border-b-[1px]'
								: 'text-[#FFFFFF4D] hover:text-white',
							'whitespace-nowrap'
						)}>
						Our Team
					</NavLink>
					<NavLink
						to='/#contact-us'
						className={classNames(
							location.hash === '#contact-us'
								? 'text-white text-bold border-b-[1px]'
								: 'text-[#FFFFFF4D] hover:text-white',
							'whitespace-nowrap'
						)}>
						Contact Us
					</NavLink>
					<NavLink
						to='/#about-us'
						className={classNames(
							location.hash === '#about-us'
								? 'text-white text-bold border-b-[1px]'
								: 'text-[#FFFFFF4D] hover:text-white',
							'whitespace-nowrap '
						)}>
						About Us
					</NavLink>
				</div>
			</div>
			<Disclosure
				as='nav'
				className={classNames(showBg ? 'w-full bg-[#000000ee] tablet:hidden' : 'w-full tablet:hidden')}>
				{({ open }) => (
					<>
						<div className={classNames(open ? 'bg-[#000000ee]' : '', 'mx-auto max-w-7xl px-8 sm:px-12 ')}>
							<div className='flex h-20 items-center justify-between'>
								<div className='flex items-center'>
									<div className='flex-shrink-0'>
										<img
											className='block sm:h-16 h-10 w-auto lg:hidden'
											src={logo}
											alt='EGT-SOFT'
										/>
									</div>
								</div>

								<div className='-mr-2 flex tablet:hidden'>
									{/* Mobile menu button */}
									<Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
										<span className='sr-only'>Open main menu</span>
										{open ? (
											<XMarkIcon className='block sm:h-8 sm:w-8 h-6 w-6' aria-hidden='true' />
										) : (
											<Bars3Icon className='block sm:h-8 sm:w-8 h-6 w-6' aria-hidden='true' />
										)}
									</Disclosure.Button>
								</div>
							</div>
						</div>

						<Disclosure.Panel className={classNames(open ? 'bg-[#000000ee]' : '', 'tablet:hidden ')}>
							<div className='space-y-1 px-2 pt-2 pb-3'>
								{/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
								<Disclosure.Button
									as='a'
									href='/'
									className='block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white'>
									Home
								</Disclosure.Button>

								<Disclosure.Button
									as='a'
									href='/#our-services'
									className='block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white'>
									Our Services
								</Disclosure.Button>
								<Disclosure.Button
									as='a'
									href='/#portfolio'
									className='block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white'>
									Portfolio
								</Disclosure.Button>
								<Disclosure.Button
									as='a'
									href='/#our-team'
									className='block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white'>
									Our Team
								</Disclosure.Button>
								<Disclosure.Button
									as='a'
									href='/#about-us'
									className='block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white'>
									About Us
								</Disclosure.Button>
								<Disclosure.Button
									as='a'
									href='/#contact-us'
									className='block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white'>
									Contact Us
								</Disclosure.Button>
							</div>
							<div className='flex flex-row items-center w-full justify-between px-10 py-5'>
								<a href='https://www.linkedin.com/company/egt-soft' target='_blank' rel='noreferrer'>
									<i className='fa-brands fa-linkedin-in fa-lg cursor-pointer text-white'></i>
								</a>
								<a href='https://github.com/EGT-SOFT' target='_blank' rel='noreferrer'>
									<i className='fa-brands fa-github fa-lg cursor-pointer text-white'></i>
								</a>
								<a href='https://www.facebook.com/egtsoft' target='_blank' rel='noreferrer'>
									<i className='fa-brands fa-facebook fa-lg cursor-pointer text-white'></i>
								</a>
								<a href='https://www.behance.net/egtsoft' target='_blank' rel='noreferrer'>
									<i className='fa-brands fa-behance fa-lg cursor-pointer text-white'></i>
								</a>
								<a href='https://twitter.com/EGT_SOFT' target='_blank' rel='noreferrer'>
									<i className='fa-brands fa-twitter fa-lg cursor-pointer text-white'></i>
								</a>
							</div>
						</Disclosure.Panel>
					</>
				)}
			</Disclosure>
		</div>
	);
}
