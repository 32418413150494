import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import './style.scss';
import { Project } from '../latest-projects-section/index';
import { useNavigate } from 'react-router-dom';

interface Props {
	item: Project;
	type: string;
}

const ProjectCard: React.FC<Props> = ({ item, type }) => {
	const navigate = useNavigate();

	return (
		<div className='card-container'>
			<div className='img-container'>
				<img src={item.image} alt='project1' />
			</div>
			<div className='overlay' />
			<div className='content'>
				<span>{item.type}</span>
				<h1>{item.name}</h1>
				<p>{item.description.slice(0, 50) + '...'}</p>
				<button onClick={() => navigate(`/portfolio/${type}?id=${item.id}`)}>
					<ArrowRightIcon className='h-5 w-5 rounded-full text-black text-center font-bold' />
				</button>
			</div>
		</div>
	);
};

export default ProjectCard;
