// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

//usable card
import Card from './Card';

export interface CardData {
	id: string;
	link: string;
	icon: string;
	description: string;
	title: string;
}
interface Props {
	cards: CardData[];
}

const CardSlider: React.FC<Props> = ({ cards }) => {
	return (
		<Swiper
			slidesPerView='auto'
			breakpoints={{
				320: {
					// width: 576,
					slidesPerView: 1,
					spaceBetween: 80
				},
				768: {
					// width: 768,
					spaceBetween: 50,
					slidesPerView: 2
				},
				1024: {
					spaceBetween: 100,
					slidesPerView: 3
				},
				1280: {
					spaceBetween: 50,
					slidesPerGroup: 4
				}
			}}
			className='mx-auto md:my-10 my-3 w-full sm:px-10 flex flex-row items-center justify-center'>
			{cards.map((item) => (
				<SwiperSlide key={item.id} className='w-fit mx-auto cursor-pointer'>
					<Card link={item.link} icon={item.icon} description={item.description} title={item.title} />
				</SwiperSlide>
			))}
		</Swiper>
	);
};
export default CardSlider;
