import Divider from 'shared/divider';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y } from 'swiper';
import data from 'assets/team.json';
import 'swiper/css';
import 'swiper/css/pagination';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
export interface MemberData {
	name: string;
	bio: string;
	linkedin: string;
	bechance?: string;
	github?: string;
	image: string;
	job: string;
	experience: string[];
	skills: string[];
}
interface Props {
	member: MemberData;
}

const MemberComponent: React.FC<Props> = ({ member }) => {
	const navigate = useNavigate();
	const socialMedia = () => {
		const { linkedin, bechance, github } = member;
		return {
			'linkedin-in': linkedin,
			...(bechance && { behance: bechance }),
			...(github && { github: github })
		} as { [key: string]: any };
	};

	return (
		<div className='flex flex-col items-center justify-center w-full gap-5 text-center md:flex-row md:items-start md:text-start'>
			<div className=' hover:animate-spin w-[100px] h-[100px] rounded-full overflow-hidden bg-[#EEEEEE21] flex items-center justify-center flex-shrink-0'>
				<img src={member.image} alt='person' className='w-[65px] h-[65px] object-cover rounded-full' />
			</div>
			<div className='flex flex-col items-center justify-start gap-1 md:items-start'>
				<span className='text-[#FFFFFF8C] text-[14px] '>" {member.job} "</span>
				<h1 className='text-white font-medium leading-6 text-[20px]'>{member.name}</h1>
				<p className='w-[85%] text-[#999999] text-[14px]'>{member.bio.slice(0, 125) + '...'}</p>
				<div className='flex flex-row items-center justify-start gap-3 my-2'>
					{Object.keys(socialMedia()).map((item) => (
						<a href={socialMedia()[item]} target='_blank' rel='noreferrer' key={item}>
							<i className={`fa-brands fa-${item}  text-white`}></i>
						</a>
					))}
				</div>
				<button
					onClick={() => navigate('/member/' + member.name)}
					className='flex items-center mt-4 text-white transition-all duration-500 hover:translate-x-2 hover:scale-110'>
					Explore More <motion.i className='ml-1  fa-solid fa-arrow-right-long'></motion.i>
				</button>
			</div>
		</div>
	);
};
const TeamProfiles = () => {
	return (
		<div className=' py-10 relative bg-[url("assets/images/background_arrows/arrow1.png")] bg-[length:400px_600px] bg-no-repeat bg-center w-full md:px-16 px-8 flex flex-col items-center justify-center mx-auto my-40 gap-10'>
			<div className='bottom-14 absolute -z-10 w-full h-[700px] opacity-25 bg-gradient-to-l from-[#377dff] to-[#8504c4] blur-[150px]' />
			<div className='flex flex-col items-start justify-start w-full gap-3'>
				<span className='text-[#FFFFFFCC] font-light'>Our Team</span>
				<h1 className='text-3xl font-semibold text-white sm:text-4xl'>Team Work " EGT-Soft !!</h1>
				<Divider />
				<p className='text-[#FFFFFFB2] w-full h-auto font-poppins text-sm text-left  md:w-90 text-wrap max-w-lg leading-8'>
					In any industry where the people behind a company are as important as the company itself, we love to
					show off the awesome people who are responsible for keeping our business booming
				</p>
			</div>
			<Swiper
				slidesPerView={1}
				centeredSlides={true}
				modules={[Pagination, A11y]}
				loop
				pagination={{ clickable: true }}
				className='w-full lg:block hidden h-[65vh]'>
				<SwiperSlide className='grid items-start justify-start w-full grid-cols-2 gap-20'>
					<MemberComponent member={data.members[0]} />
					<MemberComponent member={data.members[1]} />
					<MemberComponent member={data.members[2]} />
					<MemberComponent member={data.members[3]} />
				</SwiperSlide>
				<SwiperSlide className='grid items-start justify-start w-full grid-cols-2 gap-20'>
					<MemberComponent member={data.members[4]} />
					<MemberComponent member={data.members[5]} />
					<MemberComponent member={data.members[6]} />
					<MemberComponent member={data.members[7]} />
				</SwiperSlide>
				<SwiperSlide className='grid items-start justify-start w-full grid-cols-2 gap-20'>
					<MemberComponent member={data.members[8]} />
					<MemberComponent member={data.members[9]} />
					<MemberComponent member={data.members[10]} />
					<MemberComponent member={data.members[11]} />
				</SwiperSlide>
				<SwiperSlide className='grid items-start justify-start w-full grid-cols-2 gap-20'>
					<MemberComponent member={data.members[12]} />
					<MemberComponent member={data.members[13]} />
				</SwiperSlide>
			</Swiper>
			<Swiper
				slidesPerView={1}
				centeredSlides={true}
				modules={[Pagination, A11y]}
				loop={true}
				pagination={{ clickable: true }}
				className='w-full lg:hidden block min-h-[300px] h-fit'>
				{data.members.map((member: MemberData) => (
					<SwiperSlide
						className='grid items-start justify-start w-full grid-cols-1 gap-20 mb-10'
						key={member.name}>
						<MemberComponent member={member} />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default TeamProfiles;
