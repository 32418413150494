import logo from 'assets/images/logo.png';
import { Link } from 'react-router-dom';

const navigation = {
	solutions: [
		{ name: 'Contact Us', href: '/#contact-us' },
		{ name: 'About Us', href: '/#about-us' },
		{ name: 'Latest Projects', href: '/#portfolio' },
		{ name: 'Services', href: '/#our-services' }
	],
	support: [
		{ name: 'Pricing', href: '#' },
		{ name: 'Documentation', href: '#' },
		{ name: 'Guides', href: '#' },
		{ name: 'API Status', href: '#' }
	],
	company: [
		{ name: 'About', href: '#' },
		{ name: 'Blog', href: '#' },
		{ name: 'Jobs', href: '#' },
		{ name: 'Press', href: '#' },
		{ name: 'Partners', href: '#' }
	],
	legal: [
		{ name: 'Our Team', href: '/#our-team' },
		{ name: 'Web Projects', href: '/portfolio/web?id=1' },
		{ name: 'UI/Ux Projects', href: '/portfolio/ui_ux?id=1' }
	],
	social: [
		{
			name: 'Linkedin',
			href: 'https://www.linkedin.com/company/egt-soft',
			icon: (props: any) => <i className='fa-brands fa-linkedin-in fa-lg cursor-pointer '></i>
		},
		{
			name: 'Facebook',
			href: 'https://www.facebook.com/egtsoft',
			icon: (props: any) => (
				<svg fill='currentColor' viewBox='0 0 24 24' {...props}>
					<path
						fillRule='evenodd'
						d='M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z'
						clipRule='evenodd'
					/>
				</svg>
			)
		},

		{
			name: 'Twitter',
			href: 'https://twitter.com/EGT_SOFT',
			icon: (props: any) => (
				<svg fill='currentColor' viewBox='0 0 24 24' {...props}>
					<path d='M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84' />
				</svg>
			)
		},
		{
			name: 'GitHub',
			href: 'https://github.com/EGT-SOFT',
			icon: (props: any) => (
				<svg fill='currentColor' viewBox='0 0 24 24' {...props}>
					<path
						fillRule='evenodd'
						d='M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z'
						clipRule='evenodd'
					/>
				</svg>
			)
		},
		{
			name: 'Behance',
			href: 'https://www.behance.net/egtsoft',
			icon: (props: any) => <i className='fa-brands fa-behance fa-lg cursor-pointer'></i>
		}
	]
};

export default function Footer() {
	return (
		<footer className='footer-bg-arrows relative w-full' aria-labelledby='footer-heading'>
			<div className='bottom-24 absolute -z-10 w-full h-[200px] opacity-25 bg-gradient-to-l from-[#377dff] to-[#8504c4] blur-[150px]' />

			<div className='bottom-0  left-0 absolute -z-5 w-full h-full opacity-75 bg-[#FFFFFF14]' />
			<div className='relative z-[10000] mx-auto w-[95%] py-10 px-4 sm:px-6 lg:pt-16 lg:pb-10 '>
				<div className='xl:grid xl:grid-cols-5 xl:gap-10'>
					<div className='space-y-8 xl:col-span-2'>
						<img className='h-20' src={logo} alt='Company name' />
						<p className='text-base text-gray-500'>
							Making the world a better place through constructing elegant hierarchies.
						</p>
						<div className='flex space-x-6'>
							{navigation.social.map((item) => (
								<a
									key={item.name}
									href={item.href}
									target='_blank'
									rel='noreferrer'
									className='text-gray-400  hover:text-white'>
									<span className='sr-only'>{item.name}</span>
									<item.icon className='h-6 w-6' aria-hidden='true' />
								</a>
							))}
						</div>
					</div>
					<div className='mt-12 grid grid-cols-2 gap-8 md:gap-16 xl:col-span-1 xl:mt-0 justify-between items-start h-full'>
						<div>
							<h3 className='text-base font-medium text-white whitespace-nowrap'>ShortCut Links</h3>
							<ul className='mt-4 space-y-4'>
								{navigation.solutions.map((item) => (
									<li key={item.name}>
										<Link
											to={item.href}
											className='text-base whitespace-nowrap text-gray-300 hover:text-white cursor-pointer'>
											{item.name}
										</Link>
									</li>
								))}
							</ul>
						</div>
						<div>
							<h3 className='text-base font-medium text-white whitespace-nowrap'>ShortCut Links</h3>
							<ul className='mt-4 space-y-4'>
								{navigation.legal.map((item) => (
									<li key={item.name}>
										<Link
											to={item.href}
											className='text-base whitespace-nowrap hover:text-white cursor-pointer text-gray-300'>
											{item.name}
										</Link>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className=' col-span-2 mt-8 xl:mt-0 xl:ml-8'>
						<h3 className='text-base font-medium text-white'>Subscribe to our newsletter</h3>
						<p className='mt-4 text-base text-gray-300'>
							The latest news, and articles sent to your inbox weekly.
						</p>
						<form className='mt-4 sm:flex sm:max-w-md'>
							<label htmlFor='email-address' className='sr-only'>
								Email address
							</label>
							<input
								type='email'
								name='email-address'
								id='email-address'
								autoComplete='email'
								required
								className='w-full min-w-0 appearance-none rounded-md border border-transparent bg-white py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:border-white focus:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
								placeholder='Enter your email address'
							/>
							<div className='mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0'>
								<button
									type='submit'
									className='flex w-full items-center justify-center rounded-md border border-transparent bg-[#377DFF] py-2 px-4 text-base font-medium text-white hover:bg-[#377DFF] focus:outline-none focus:ring-2 focus:ring-[#377DFF] cursor-pointer focus:ring-offset-2 focus:ring-offset-gray-800'>
									Get Started
								</button>
							</div>
						</form>
					</div>
				</div>
				<div className='mt-12 border-t border-gray-200 pt-8'>
					<p className='text-base text-gray-400 xl:text-center'>
						&copy; 2022 EGT-SOFT, Inc. All rights reserved.
					</p>
				</div>
			</div>
		</footer>
	);
}
