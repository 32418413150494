import React from 'react';
import { useNavigate } from 'react-router-dom';
import Divider from 'shared/divider';

type Props = {};

const AboutUs = (props: Props) => {
	const nav = useNavigate();
	return (
		<div className='bg-[#D9D9D905] min-h-[50vh] h-fit w-full flex lg:flex-row flex-col-reverse items-center justify-between relative my-40'>
			<div className='bottom-28 absolute -z-10 w-full h-[200px] opacity-50 bg-gradient-to-l from-[#377dff] to-[#8504c4] blur-[150px]' />
			<div className='lg:w-[50%] w-full min-h-[50vh] h-fit py-10  px-6 md:px-10 lg:px-10 xl:px-16 flex flex-col items-start justify-between gap-3'>
				<span className='text-[#FFFFFFCC]'>About Us</span>
				<h1 className='text-white text-3xl sm:text-4xl'>What is EGT-Soft ?!</h1>
				<Divider />
				<p className='text-sm text-[#FFFFFFB2]'>
					As the company evolved over the years, we achieved our goal of providing a truly end-to- end
					development solution, completing our mission to provide what you need, when you need it. We plan to
					create stories and issues and solve it in the way that fits your organization the most And discuss
					our work in full context with complete visibility, Shape with confidence knowing the technology you
					have is always up to date.
				</p>
				<p className='text-sm sm:visible hidden text-[#FFFFFFB2]'>
					As the company evolved over the years, we achieved our goal of providing a truly end-to-end
				</p>
				<button
					className='bg-[#377DFF] text-white w-[220px] h-[50px] rounded-[7px]'
					onClick={() => nav('/#contact-us')}>
					Contact Us
				</button>
			</div>
			<iframe
				src='https://www.youtube.com/embed/2Dj3yBEkZrg'
				title='EGT-SOFT'
				className='lg:w-[50%] w-full h-[50vh]'
				allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
				allowFullScreen></iframe>
		</div>
	);
};

export default AboutUs;
