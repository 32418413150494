import Footer from 'components/footer';
import Navbar from 'components/navbar';
import React from 'react';
import Divider from 'shared/divider';
import { v4 as uuidv4 } from 'uuid';
import { Tabs } from 'models/tabs.enums';
import tools from 'assets/images/ruler&pen.png';
import monitor from 'assets/images/monitor.png';
import Vector from 'assets/images/Vector.png';
import magic from 'assets/images/magicpen.png';

//image
import lines from 'assets/images/lines.png';
import { CardData } from 'components/Services/ServicesSection/CardSlider';
import Card from 'components/Services/ServicesSection/Card';
import { Project } from 'components/latest-projects-section';
import { useParams } from 'react-router-dom';

interface Props {
	// target: any;
}
const skills = [
	{ id: 'A4', name: 'HTML' },
	{ id: 'A5', name: 'CSS' },
	{ id: 'A11', name: 'Reactjs' },
	{ id: 'A16', name: 'Scss' },
	{ id: 'A7', name: 'TypeScript' },
	{ id: 'A9', name: 'TailWindCSS' }
];
interface Target extends CardData {
	tools: string[];
	name: string;
}

const Services: React.FC<Props> = () => {
	const params = useParams();
	const Cards = [
		{
			id: uuidv4(),
			link: Tabs.UI_UX,
			icon: tools,
			name: 'UI/UX Design',
			title: 'What is UI/UX Design ?!',
			description:
				'We have specialists in the field of UI/UX Design We can provide a high-quality product experience besides paying attention to the shape of the product. We strive to offer you in this part, everything that is big and great ',
			tools: ['Figma', 'adobe xd', 'sketch', 'adobe illustrator', 'adobe photoshop']
		},
		{
			id: uuidv4(),
			link: Tabs.MOBILE,
			icon: Vector,
			name: 'Mobile development',
			title: 'What is Mobile development ?!',
			description: 'Appnovation designs, develops and delivers mobile apps across all the major ..',
			tools: ['Kotlin', 'Swift', 'Swift UI', 'Flutter', 'React Native', 'Java']
		},
		{
			id: uuidv4(),
			link: Tabs.WEB,
			icon: monitor,
			name: 'Web Development',
			title: 'What is Web Development ?!',
			description: 'Designing, coding and modifying websites from layout to function and ..',
			tools: [
				'Nextjs',
				'Reactjs',
				'Angular',
				'Vuejs',
				'Redux toolkit',
				'Sass',
				'Css',
				'JavaScript',
				'Typescript',
				'Tailwind-Css'
			]
		},
		{
			id: uuidv4(),
			link: Tabs.GRAPHIC,
			icon: magic,
			name: 'Graphic Design',
			title: 'What is Graphic Design ?!',
			description: 'Prepares work to be accomplished by gathering information and materials .. ',
			tools: ['adobe illustrator', 'Figma', 'adobe xd', 'sketch', 'adobe indesign', 'adobe photoshop']
		}
	];
	const target = Cards.find((item: Target) => item.link === params.id);
	return (
		<main className='w-full min-h-screen h-fit flex flex-col md:items-start justify-end gap-10'>
			<Navbar />
			<div className='flex mt-32 md:mt-0 md:mb-0 mb-10 justify-center w-full items-center md:min-h-[80vh] min-h-fit '>
				<div className='flex md:flex-row md:h-[360px] flex-col justify-start items-center w-full'>
					{/* project cover */}
					<div className='relative w-full md:w-6/12 bg-[#377DFF03] h-full flex flex-col items-center justify-center gap-5 text-center'>
						<img src={lines} alt='lines' className='absolute rotate-90 right-0 w-[300px]' />
						<div className=' w-[100px] h-[100px] bg-[#FFFFFF2B] flex items-center justify-center rounded-full'>
							<img
								className='md:w-[50px] h-[50px] object-contain object-center rounded-md w-full sm:w-8/12'
								src={target?.icon}
								alt='projectImage'
							/>
						</div>
						<h1 className='text-white text-2xl font-semibold'>{target?.name}</h1>
						<p className='text-[#FFFFFFC9] w-full max-w-md text-sm'>
							Creating the product as you envisioned it by the user experience and targeted ..
						</p>
						<img src={lines} alt='lines' className='absolute rotate-90 left-0 w-[300px]' />
					</div>
					{/* project description */}
					<div className='sm:px-10 px-4 w-full md:w-8/12 h-full md:bg-[#D9D9D905]'>
						<img src={lines} alt='lines' className='absolute  right-4  w-[300px]' />
						<div className='flex flex-col justify-center items-start w-full h-full md:mt-0 mt-4'>
							<p className='text-white text-md font-poppins opacity-80 my-3'>Our Services</p>
							<p className='text-white text-2xl sm:text-3xl sm:text-3xl md:text-4xl font-poppins font-bold mb-3'>
								{target?.title}
							</p>
							<Divider />
							<p className='text-white opacity-80 w-full h-auto font-poppins text-sm text-left mt-4 md:w-full text-wrap max-w-lg leading-normal'>
								{target?.description}
							</p>
							<p className='text-white opacity-90 w-full h-auto font-poppins text-sm text-left mt-4 md:w-full text-wrap max-w-md leading-normal'>
								The following design tools were used :
							</p>

							<ul className='flex flex-row justify-start w-full items-center flex-wrap mt-4 mb-1 text-white gap-x-9 gap-y-3  list-disc pl-5 '>
								{target?.tools.map((skill) => (
									<li key={skill} className='text-start'>
										{skill}
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</main>
	);
};

export default Services;
