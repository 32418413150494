// arrow icon
import arrow from 'assets/images/arrow-right.png';
import { useNavigate } from 'react-router-dom';
interface CardProps {
	link: string;
	title: string;
	description: string;
	icon: any;
}

const Card = ({ link, title, description, icon }: CardProps) => {
	const Navigate = useNavigate();

	const NavigationHandler = () => {
		Navigate('/service/' + link);
	};
	return (
		<div className='flex flex-col justify-around items-center gap-8 p-6 self-center bg-[#191C26] hover:bg-gradient-to-b from-[#377DFF] to-[#8504C4] hover:bg-opacity-60 rounded-md h-96 w-80 max-w-96 flex-1 mx-auto'>
			<div className='bg-[#FFFFFF] bg-opacity-25 rounded-full w-16 h-16 flex justify-center items-center'>
				<img src={icon} alt='logo' className='object-contain w-10 h-10' />
			</div>
			{/* title */}
			<h2 className='text-white text-lg font-bold font-poppins'>{title}</h2>
			{/* description */}
			<p className='text-white opacity-75 text-center font-light mx-1 leading-relaxed '>{description}</p>

			{/* button */}
			<button
				className='text-white text-base bg-opacity-0 flex items-center justify-center p-1 hover:translate-x-3  transition duration-500'
				onClick={NavigationHandler}>
				<p>Explore more</p>
				<img src={arrow} alt='right arrow' className='object-contain w-6 h-6' />
			</button>
		</div>
	);
};

export default Card;
