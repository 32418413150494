import React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {};

const UPButton = (props: Props) => {
	const navigate = useNavigate();
	const [showBtn, setShowBtn] = React.useState<boolean>(false);
	window.addEventListener('scroll', () => {
		if (window.scrollY > 800) setShowBtn(true);
		else setShowBtn(false);
	});
	const handleGoUp = () => {
		navigate('/');
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};
	return (
		<>
			<div style={{ display: `${showBtn ? 'block' : 'none'}` }} className='up_container'>
				<button onClick={handleGoUp}>
					<i className='fa-solid fa-arrow-up-long text-white'></i>
				</button>
			</div>
			<a
				href='https://wa.me/+201282211753'
				target='_blank'
				rel='noreferrer'
				className='fixed block sm:bottom-8 sm:left-8 left-[2%] bottom-[4%] w-[60px] h-[60px] z-[10000000] cursor-pointer'
				style={{ display: `${showBtn ? 'block' : 'none'}` }}>
				<svg width='55' height='55' viewBox='0 0 61 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<rect y='51' width='51' height='51' rx='30.5' transform='rotate(-90 5 51)' fill='#8504C4' />
					<path
						d='M37.5369 34.4647C37.5226 34.9642 37.3675 35.4494 37.0894 35.8645C36.8113 36.2796 36.4215 36.6077 35.965 36.8109C35.4912 37.0698 34.9668 37.2228 34.428 37.2593C33.8893 37.2957 33.349 37.2148 32.8446 37.022C31.8786 36.6717 30.9311 36.2723 30.0058 35.8255C28.8104 35.1922 27.7441 34.3408 26.8619 33.3151C25.8611 32.2144 25.002 30.9927 24.3046 29.6786C23.7815 28.7679 23.5368 27.7238 23.6008 26.6755C23.649 26.1342 23.8121 25.6094 24.0791 25.1361C24.3461 24.6627 24.7109 24.2518 25.1493 23.9305C25.3514 23.7937 25.5861 23.7127 25.8296 23.6959C26.1263 23.6719 26.4245 23.6719 26.7212 23.6959C26.8297 23.7096 26.9318 23.7547 27.0151 23.8255C27.0985 23.8963 27.1594 23.9899 27.1904 24.0947C27.425 24.6344 27.6127 25.174 27.8239 25.737C28.035 26.3001 28.0819 26.4878 28.2462 26.8397C28.3905 27.0336 28.4605 27.273 28.4433 27.5141C28.426 27.7552 28.3228 27.9822 28.1523 28.1536C27.9265 28.4486 27.6752 28.7234 27.4016 28.9747C27.3014 29.0536 27.2341 29.1668 27.2124 29.2924C27.1907 29.418 27.2163 29.5472 27.2843 29.6551C27.9194 30.8087 28.7626 31.8348 29.7712 32.6817C30.4941 33.2239 31.2824 33.6732 32.1173 34.019C32.2038 34.0981 32.3168 34.142 32.4341 34.142C32.5513 34.142 32.6643 34.0981 32.7508 34.019L33.9239 32.6817C34.1819 32.4001 34.2993 32.3297 34.6277 32.494L37.5135 33.9955C37.5525 34.0669 37.5749 34.1462 37.579 34.2274C37.583 34.3087 37.5687 34.3898 37.5369 34.4647Z'
						fill='white'
					/>
					<path
						d='M16.2832 44.9519L17.4094 41.5969C17.7847 40.5177 18.0897 39.415 18.512 38.3592C18.6273 38.1341 18.6875 37.8848 18.6875 37.6319C18.6875 37.379 18.6273 37.1297 18.512 36.9046C17.1781 34.4419 16.6337 31.6286 16.9527 28.8461C17.2717 26.0636 18.4385 23.4465 20.2951 21.3496C21.643 19.8131 23.3251 18.606 25.2123 17.8211C27.0994 17.0361 29.1414 16.6942 31.1813 16.8215C34.2731 16.9235 37.2303 18.1114 39.5336 20.1765C41.7117 22.0723 43.2316 24.6109 43.874 27.4261C44.4104 29.7027 44.3458 32.0797 43.6863 34.3238C42.88 37.1438 41.1644 39.6182 38.8063 41.3623C37.245 42.5782 35.4342 43.4338 33.5035 43.8678C31.5728 44.3018 29.57 44.3035 27.6386 43.8727C26.4167 43.6269 25.2384 43.1999 24.1428 42.6057C24.0584 42.566 23.9663 42.5454 23.873 42.5454C23.7797 42.5454 23.6876 42.566 23.6032 42.6057L17.3155 44.5765L16.2832 44.9519ZM19.8494 41.4092H20.0605L24.002 40.1657C24.139 40.1193 24.2874 40.1193 24.4244 40.1657C24.9874 40.4473 25.5271 40.7992 26.1136 41.0338C28.4511 42.0605 31.0746 42.2348 33.5274 41.5265C34.9701 41.1412 36.3213 40.4716 37.5018 39.557C38.6822 38.6425 39.6681 37.5014 40.4017 36.2007C41.8574 33.6668 42.2868 30.6713 41.6017 27.8304C40.9166 24.9895 39.1689 22.5191 36.7182 20.9273C34.4993 19.4877 31.8489 18.8644 29.2209 19.164C26.5929 19.4636 24.1508 20.6675 22.3128 22.5696C20.8083 24.1059 19.7815 26.0455 19.3567 28.1534C19.0227 29.5986 18.9857 31.0965 19.2479 32.5564C19.51 34.0163 20.0658 35.4078 20.8817 36.6465C21.0141 36.866 21.0841 37.1175 21.0841 37.3738C21.0841 37.6302 21.0141 37.8816 20.8817 38.1011C20.5767 39.1334 20.2482 40.2596 19.8494 41.4092Z'
						fill='white'
					/>
				</svg>
			</a>
		</>
	);
};

export default UPButton;
