// member page custom components
import Avatar from './Avatar';
import ContactsMe from './ContactsMe';
import CustomList from './CustomList';
import MemberDescription from './MemberDescription';

//background
import lines from 'assets/images/sidelines.png';
import { useParams } from 'react-router-dom';
import members from 'assets/team.json';
import { MemberData } from '../team-profiles-section/index';

const MemberComponent = () => {
	const params = useParams();
	const target: MemberData = members.members.find((item) => item.name === params.id) as MemberData;
	const socialMedia = () => {
		const { linkedin, bechance, github } = target;
		return {
			'linkedin-in': linkedin,
			...(bechance && { behance: bechance }),
			...(github && { github: github })
		} as { [key: string]: any };
	};
	return (
		<div className='relative w-full min-h-screen h-auto flex items-center justify-center '>
			<img src={lines} alt='linesbackground' className='absolute top-0 right-10 ' />
			<img src={lines} alt='linesbackground' className='absolute -bottom-16 left-0 ' />

			<div className='w-full h-auto  flex flex-wrap justify-center items-center py-2 md:flex-row  md:items-stretch bg-[#D9D9D9] bg-opacity-[0.02]'>
				{/* left side */}
				<div className='h-auto flex justify-center items-center w-full gap-5 flex-wrap mx-3 md:w-7/12 md:pb-10'>
					<Avatar member={target} />
					<MemberDescription member={target} />
					<div className='flex flex-wrap w-full gap-5'>
						<CustomList list={{ data: target.experience, title: 'Experience' }} />
						<CustomList list={{ data: target.skills, title: 'Skills' }} />
					</div>
				</div>
				{/* right side */}
				<div className=' p-3 w-full md:w-4/12    md:items-end md:flex '>
					<ContactsMe data={socialMedia()} />
				</div>
			</div>
		</div>
	);
};

export default MemberComponent;
