import { Link, useNavigate } from 'react-router-dom';
import './style.scss';
import cloud from 'assets/images/cloud.png';
import Navbar from 'components/navbar';
import mouse from 'assets/images/Vector.png';
interface Props {}

const HeroSection: React.FC<Props> = () => {
	const navigate = useNavigate();
	return (
		<div className='hero-section-bg-arrows w-full h-screen relative flex flex-row items-center justify-center'>
			<Navbar />
			<div className='top-[50%] translate-y-[-50%] absolute -z-10 w-full h-[60vh] opacity-25 bg-gradient-to-l from-[#377dff] to-[#8504c4] blur-[150px]' />
			<div className='absolute top-[50%] opacity-40 left-[50%] translate-x-[-50%] translate-y-[-50%]'>
				<img alt='cloud' src={cloud} />
			</div>
			<div className='relative z-[11000] mx-auto flex flex-col items-center justify-center gap-8 flex-shrink-0 w-full md:px-20 px-5'>
				<div className='text-center text-white flex flex-col items-center justify-center gap-3'>
					<span className='text-[#FFFFFFCC] text-sm font-light'>Welcome to</span>
					<h1 className='text-5xl lg:text-7xl py-4 font-bold text-white'>EGT-SOFT</h1>
					<p className='text-[#FFFFFFB2] leading-8 text-sm font-light pt-1'>
						With the development of the company over the years, we have <br className='hidden sm:block' />
						achieved our goal of providing Comprehensive development solution to problems
					</p>
					<p className='text-[#FFFFFFB2] text-sm font-light pb-1'></p>
					<div className='flex flex-col-reverse sm:flex-row w-full items-center justify-center gap-10 sm:py-6'>
						<Link
							to={'/#contact-us'}
							className='bg-linear block w-full rounded sm:w-60  items-center justify-center  border border-transparent bg-linear py-4 px-4 text-base font-medium text-white '>
							Contact Us
						</Link>
						<div className='flex'>
							<button onClick={() => navigate('/#about-us')} className='play-btn'>
								<svg
									width='41'
									height='41'
									viewBox='0 0 41 41'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'>
									<circle cx='20.5' cy='20.5' r='19.918' fill='white' />
									<path
										d='M27.0428 19.975C27.689 20.3637 27.689 21.3004 27.0428 21.689L18.0323 27.1079C17.3658 27.5088 16.517 27.0287 16.517 26.251L16.517 15.413C16.517 14.6353 17.3658 14.1552 18.0323 14.5561L27.0428 19.975Z'
										fill='#8504C4'
									/>
								</svg>
							</button>
							<Link
								to='/#about-us'
								className='w-32 px-4 py-4 text-base font-medium text-gray-300 hover:text-white'>
								About Us
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className='hidden absolute z-[5000000] left-8 h-full text-white tablet:flex flex-col  gap-6 pb-16 top-[50%] translate-y-[-50%]'>
				<div className='flex flex-col items-center'>
					<div className='h-[25vh] w-px bg-[#ffffff4a]'></div>
					<div className='icons flex flex-col gap-4 items-center w-6'>
						{/* <a href='https://markosbahgat.com' target='_blank' rel='noreferrer'>
							<i className='fa-brands fa-instagram fa-lg'></i>
						</a> */}
						<a href='https://www.linkedin.com/company/egt-soft' target='_blank' rel='noreferrer'>
							<i className='fa-brands fa-linkedin-in fa-lg cursor-pointer'></i>
						</a>
						<a href='https://github.com/EGT-SOFT' target='_blank' rel='noreferrer'>
							<i className='fa-brands fa-github fa-lg cursor-pointer'></i>
						</a>
						<a href='https://www.facebook.com/egtsoft' target='_blank' rel='noreferrer'>
							<i className='fa-brands fa-facebook fa-lg cursor-pointer'></i>
						</a>
						<a href='https://www.behance.net/egtsoft' target='_blank' rel='noreferrer'>
							<i className='fa-brands fa-behance fa-lg cursor-pointer'></i>
						</a>
						<a href='https://twitter.com/EGT_SOFT' target='_blank' rel='noreferrer'>
							<i className='fa-brands fa-twitter fa-lg cursor-pointer'></i>
						</a>
					</div>
					<div className='h-[25vh] w-px bg-[#ffffff4a]'></div>
				</div>

				<div className='flex flex-col items-center  gap-5 '>
					<div className='rotate-90'>Scroll</div>
					<img src={mouse} alt='' className='h-5 w-5 object-contain' />
				</div>
			</div>
		</div>
	);
};

export default HeroSection;
