interface Props {
	data: { [key: string]: any };
}
const ContactsMe: React.FC<Props> = ({ data }) => {
	return (
		<div className='w-full h-full gap-4 md:gap-0 md:h-[100px] md:mb-20 flex flex-wrap justify-start items-center md:pl-5'>
			<h1 className='text-white  font-medium leading-6 text-[18px] w-full text-start'>Contacts me :</h1>
			<div className='flex gap-3 items-center md:w-full md:gap-2 '>
				{Object.keys(data).map((item) => (
					<a
						href={data[item]}
						target='_blank'
						rel='noreferrer'
						className='w-[50px] h-[50px] md:w-[40px] md:h-[40px] flex justify-center items-center rounded-full bg-[#FFFFFF] bg-opacity-[9.3%] flex-grow-0'
						key={item}>
						<i className={`fa-brands fa-${item}  text-white`}></i>
					</a>
				))}
			</div>
		</div>
	);
};

export default ContactsMe;
