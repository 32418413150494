// routes
import Home from 'routes/home';
import Portfolio from 'routes/portfolio';

import { Routes, Route } from 'react-router-dom';
import Services from 'routes/services';
import Members from 'routes/members';

//some static skills for only testing puproses

function App() {
	return (
		<Routes>
			<Route path='/' element={<Home />} />
			<Route path='/portfolio/:id' element={<Portfolio />} />
			<Route path='/member/:id' element={<Members />} />
			<Route path='/service/:id' element={<Services />} />
		</Routes>
	);
}

export default App;
