import { MemberData } from 'components/team-profiles-section';
import React from 'react';

interface Props {
	member: MemberData;
}
const Avatar: React.FC<Props> = ({ member }) => {
	return (
		<div className='w-full  felx justify-center items-center md:pl-3'>
			<div className='w-auto flex justify-center items-center p-5 md:justify-start '>
				<div className='w-[100px] h-[100px]  rounded-full overflow-hidden bg-[#EEEEEE21] flex items-center justify-center flex-shrink-0'>
					<img src={member.image} alt='person' className='w-[65px] h-[65px] object-cover rounded-full' />
				</div>
			</div>
			<div className='h-16 mx-3 justify-center items-center gap-5 flex-wrap'>
				<span className='text-[#FFFFFF8C] text-[14px] block text-center h-1/2 md:text-left '>
					" {member.job} "
				</span>
				<h1 className='text-white font-medium leading-6 text-[20px] w-auto text-center md:text-left'>
					{member.name}
				</h1>
			</div>
		</div>
	);
};

export default Avatar;
