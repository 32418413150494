import React from 'react';

interface Props {
	list: {
		title: string;
		data: string[];
	};
}
const CustomList: React.FC<Props> = ({ list }) => {
	return (
		<div className='w-full md:pl-6'>
			<h1 className='text-white font-medium leading-6 text-[18px] w-auto text-left'>{list.title} :</h1>
			<ul className='list-disc pl-8 flex flex-wrap justify-start gap-x-8 '>
				{list.data.map((item: string, index: number) => (
					<li key={index} className='my-2 text-[#999999] w-auto'>
						{item}
					</li>
				))}
			</ul>
		</div>
	);
};

export default CustomList;
