import React from 'react';
import divider from 'assets/images/divider.png';
import { StarIcon } from '@heroicons/react/20/solid';
import { classNames } from 'shared';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y } from 'swiper';
import data from 'assets/reviews.json';
import 'swiper/css';
import 'swiper/css/pagination';

type Props = {};

const Reviews = (props: Props) => {
	return (
		<div className='w-full px-5 reviews-bg-arrows h-fit min-h-[50vh] py-10 flex flex-col items-center justify-center text-center relative'>
			<div className='reviews-custom w-full min-h-[50vh] h-fit absolute -z-1 top-0 left-0' />
			<div className='flex flex-col items-center justify-center gap-1 text-center mb-8 mx-auto'>
				<img src={divider} alt='divider' className='w-[400px] object-contain' />
				<h1 className='text-xl sm:text-2xl text-white leading-10 text-center font-semibold'>
					Meet Client Satisfaction After Working with Us
				</h1>
				<p className='text-[#FFFFFFC7] text-sm font-medium'>EGT-SOFT is the best ever.</p>
			</div>
			<Swiper
				slidesPerView={1}
				spaceBetween={100}
				modules={[Pagination, A11y]}
				loop
				pagination={{ clickable: true }}
				className='w-full h-fit flex items-center justify-center'>
				{data.reviews.map((review, reviewIdx) => (
					<SwiperSlide
						key={review.id}
						className='flex flex-col items-center justify-center w-full mx-auto text-center text-sm text-gray-500 mb-10'>
						<div className='mt-4 mx-auto flex items-center'>
							{[0, 1, 2, 3, 4].map((rating) => (
								<StarIcon
									key={rating}
									className={classNames(
										review.rating > rating ? 'text-yellow-400' : 'text-gray-300',
										'h-5 w-5 flex-shrink-0'
									)}
									aria-hidden='true'
								/>
							))}
						</div>
						<p className='w-full max-w-2xl mx-auto mt-4 leading-7  text-[#FFFFFF80]'>{review.content}</p>
						<div className='flex gap-3 items-center justify-center'>
							<div className='flex-none py-5'>
								<img src={review.avatarSrc} alt='' className='h-10 w-10 rounded-xl bg-gray-100' />
							</div>
							<div
								className={classNames(
									reviewIdx === 0 ? '' : 'border-t border-gray-200',
									'flex-1 py-5'
								)}>
								<h3 className='font-medium text-white'>{review.author}</h3>
								<p>
									<time dateTime={review.datetime} className='text-[#FFFFFF80]'>
										{review.date}
									</time>
								</p>
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default Reviews;
