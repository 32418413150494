import React from 'react';
//my custom card
import CardSlider from './CardSlider';
// divider icon
import Divider from 'shared/divider';

//styles
import './style.scss';
//icons urls
import tools from 'assets/images/ruler&pen.png';
import monitor from 'assets/images/monitor.png';
import Vector from 'assets/images/Vector.png';
import magic from 'assets/images/magicpen.png';

//unique id creation function
import { v4 as uuidv4 } from 'uuid';
import { Tabs } from '../../../models/tabs.enums';

//image
import lines from 'assets/images/lines.png';
import { CardData } from './CardSlider';
import Card from './Card';

interface Props {}
const Services: React.FC<Props> = () => {
	const Cards: CardData[] = [
		{
			id: uuidv4(),
			link: Tabs.UI_UX,
			icon: tools,
			title: 'UI/UX Design',
			description: 'Creating the product as you envisioned it by the user experience and targeted ..'
		},
		{
			id: uuidv4(),
			link: Tabs.MOBILE,
			icon: Vector,
			title: 'Mobile development',
			description: 'Appnovation designs, develops and delivers mobile apps across all the major ..'
		},
		{
			id: uuidv4(),
			link: Tabs.WEB,
			icon: monitor,
			title: 'Web Development',
			description: 'Designing, coding and modifying websites from layout to function and ..'
		},
		{
			id: uuidv4(),
			link: Tabs.GRAPHIC,
			icon: magic,
			title: 'Graphic Design',
			description: 'Prepares work to be accomplished by gathering information and materials .. '
		}
	];
	return (
		<div className='relative w-full py-20 bg-gradient-to-b from-[#D9D9D900] to-[#D9D9D903] min-h-screen'>
			<div className='top-40 absolute -z-10 w-full h-[300px] opacity-50 bg-gradient-to-l from-[#377dff] to-[#8504c4] blur-[150px]' />
			<img src={lines} alt='background' className='image' />

			<div className='xl:p-16 lg:p-10 p-6 w-full h-fit'>
				<div className='flex flex-col justify-start items-start mx-2 mb-28'>
					<p className='text-[#FFFFFFCC] text-md font-poppins opacity-80 my-3'>Our Services</p>
					<p className='text-white text-3xl sm:text-4xl font-poppins font-bold mb-3'>We Do Everything !!</p>
					<Divider />
					<p className='text-[#FFFFFFB2] w-full h-auto font-poppins text-sm text-left mt-4 md:w-90 text-wrap max-w-lg leading-normal'>
						With the development of the company over the years, we have achieved our goal of providing
						Comprehensive development solution to problems
					</p>
				</div>
				<div className='hidden sm:block'>
					<CardSlider cards={Cards} />
				</div>
				<div className='sm:hidden flex flex-col items-center justify-center gap-5'>
					{Cards.map((item) => (
						<Card {...item} key={item.id} />
					))}
				</div>
			</div>
		</div>
	);
};

export default Services;
