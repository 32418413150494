//text divider
import Divider from 'shared/divider';
// //background image
import lines from 'assets/images/sidelines.png';
import { useLocation, useParams } from 'react-router-dom';
import data from 'assets/projects.json';
import { Project } from '../components/latest-projects-section/index';
import Navbar from 'components/navbar';
import Footer from '../components/footer/index';

interface projectProps {}
const skills = [
	{ id: 'A4', name: 'HTML' },
	{ id: 'A5', name: 'CSS' },
	{ id: 'A11', name: 'Reactjs' },
	{ id: 'A16', name: 'Scss' },
	{ id: 'A7', name: 'TypeScript' },
	{ id: 'A9', name: 'TailWindCSS' }
];
const Portfolio: React.FC<projectProps> = () => {
	const params = useParams();
	const id = useLocation();
	const target: Project = (data as { [key: string]: any })[String(params.id)].find(
		(item: Project) => item.id === Number(id.search.split('=')[1])
	);

	return (
		<main className='w-full min-h-screen h-fit flex flex-col md:items-start justify-end gap-10'>
			<Navbar />
			<div className='flex mt-32 md:mt-0 md:mb-0 mb-10 justify-center w-full items-center md:min-h-[80vh] min-h-fit '>
				<div className='flex md:flex-row md:h-[360px] flex-col justify-start items-center w-full'>
					{/* project cover */}
					<img
						className='md:w-[366px]  md:ml-10 h-auto sm:h-full object-cover object-center rounded-md w-full sm:w-8/12'
						src={target.image}
						alt='projectimage'
					/>
					{/* project description */}
					<div className='sm:pl-10 pl-4 w-full h-full md:bg-[#D9D9D905]'>
						<img src={lines} alt='lines' className='absolute  right-4  w-[300px]' />
						<div className='flex flex-col justify-center items-start w-full h-full md:mt-0 mt-4'>
							<p className='text-white text-md font-poppins opacity-80 my-3'>{target.type}</p>
							<p className='text-white text-3xl md:text-4xl font-poppins font-bold mb-3'>{target.name}</p>
							<Divider />
							<p className='text-white opacity-80 w-full h-auto font-poppins text-sm text-left mt-4 md:w-full text-wrap max-w-lg leading-normal'>
								{target.description}
							</p>
							<p className='text-white opacity-90 w-full h-auto font-poppins text-sm text-left mt-4 md:w-full text-wrap max-w-md leading-normal'>
								The following design tools were used :
							</p>

							<ul className='flex flex-row justify-start w-full items-center flex-wrap mt-4 mb-1 text-white gap-x-9 gap-y-3  list-disc pl-5 '>
								{skills.map((skill) => (
									<li key={skill.id} className='text-start'>
										{skill.name}
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</main>
	);
};

export default Portfolio;
