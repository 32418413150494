import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store/store';

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		token: ''
	},
	reducers: {},
	extraReducers: (builder) => {}
});

export const authReducer = authSlice.reducer;
export const authState = (state: RootState) => state.auth;
