import React from 'react';
import { MemberData } from '../team-profiles-section/index';

interface Props {
	member: MemberData;
}
const MemberDescription: React.FC<Props> = ({ member }) => {
	return (
		<div className='w-full flex justify-start items-center gap-3  flex-wrap md:pl-6'>
			<h1 className='text-white font-medium leading-6 text-[18px] w-auto text-center'>About me :</h1>
			<p className='text-[#999999] text-sm '>{member.bio}</p>
		</div>
	);
};

export default MemberDescription;
